import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled  from 'styled-components';
import { BodyFont, HeadingFont } from './text';

const activeClassName = "active";

export const HeaderContainer = styled.header`
    align-items: center;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 5;
`;

// Branding
export const BrandContainer = styled.div`
    width: 100%;
`;

export const BrandLink = styled(NavLink)`
    display: inline-flex;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    height: ${props => props.height ? `${props.height}px` : ""};
    max-height: ${props => props.height ? `${props.height}px` : ""};  
    white-space: nowrap;
    overflow: hidden;
    margin: 30px 0;
    @media (max-width: 992px) {
        margin: 10px 0;  
    }
`;

export const NavLogo = styled.img`
    width: 100%;
    height: ${props => props.height ? `${props.height}px` : "auto"};
    max-width: ${props => props.width ? `${props.width}px` : "150px"}; 
    margin: 15px 20px;
    
    @media (max-width: 992px) {
        max-width: ${props => props.width ? `${props.width * .8}px` : "100px"}; 
        height: ${props => props.height ? `${props.height * .8}px` : "100px"}; 
        margin: 0 10px;
    }
`;

export const NavTitle = styled.span`
    ${HeadingFont}
    font-size: 3rem;
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: color 0.15s linear;

    &:hover {
        text-decoration: none;
        color: ${props => props.theme.colors.secondary};
    }
    
    @media (max-width: 992px) {
        font-size: 1.4rem;
    }
`;

// Nav Menu
export const NavMenuContainer = styled.nav`
    display: block;
    padding: 25px 0;
    @media (max-width: 992px) {
        padding: 5px 0;
    }
    /* @media (max-width: 992px) {
        display: none;
    } */
`;

export const NavLinks = styled.span`
    ${BodyFont};
`;

const hoverUnderline = (type = 'fade', dir = null, weight = '2px', color = '#FFD700') => `
    position: relative;
    display: inline-block;
    text-decoration: none;

    &::after {
        position: absolute;
        left: 0;
        content: '';
        width: 100%;
        height: ${weight};
        background: ${color};

        ${
          type === 'fade'
            ? `opacity: 0;
               visibility: hidden;
               transition: .4s;`
            : `transform: scale(0, 1);
               transition: transform .4s;`
        }

        ${
          dir === 'bottom'
            ? `bottom: ${parseFloat(weight) * -4}px;`
            : `bottom: ${weight};`
        }

        ${
          (dir === 'left-right' || dir === 'right-in-left') && type === 'slide'
            ? `transform-origin: left top;`
            : (dir === 'right-left' || dir === 'left-in-right') && type === 'slide'
              ? `transform-origin: right top;`
              : `transform-origin: center top;`
        }
    }

    &:hover::after {
        ${
          type === 'fade'
            ? `opacity: 1;
               visibility: visible;`
            : `transform: scale(1, 1);`
        }

        ${
          dir === 'left-in-right' && type === 'slide'
            ? `transform-origin: left top;`
            : dir === 'right-in-left' && type === 'slide'
              ? `transform-origin: right top;`
              : ``
        }
    }
`;

export const NavALink = styled.a`
    transition: color 0.15s linear, border-bottom 0.15s linear;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: 28px;
    margin: 0 18px;
    padding: 5px 0;

    &.${activeClassName} {
        position: relative;
        display: inline-block;
        text-decoration: none;
        &::after {
            position: absolute;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: ${props => props.theme.colors.secondary};
            bottom: 2px * -2;
            transform: scale(0, 1);
            transition: transform .4s;
            transform-origin: center top;
            transform: scale(1, 1);
        }
    }

    &:hover {
        color: ${props => props.theme.colors.secondary};
    };

    @media (max-width: 992px) {
        font-size: 18px;
        margin: 0 10px;
        padding: 3px 0;
    }
    
    ${hoverUnderline('slide', 'left-right')}
`;

export const NavLLink = styled(NavLink)`
    transition: color 0.15s linear, border-bottom 0.15s linear;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: 28px;
    margin: 0 18px;
    padding: 5px 0;

    &.${activeClassName} {
        position: relative;
        display: inline-block;
        text-decoration: none;
        &::after {
            position: absolute;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: ${props => props.theme.colors.secondary};
            bottom: 2px * -2;
            transform: scale(0, 1);
            transition: transform .4s;
            transform-origin: center top;
            transform: scale(1, 1);
        }
    }

    &:hover {
        color: ${props => props.theme.colors.secondary};
    };

    @media (max-width: 992px) {
        font-size: 18px;
        margin: 0 10px;
        padding: 3px 0;
    }
    
    ${hoverUnderline('slide', 'left-right')}
`;

// Burger Menu 
export const BgOverlay = styled.div`
    display: ${({ open }) => open ? 'inline' : 'none'};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${rgba("grey", 0.7)};
    transition: opacity 0.8s;
    z-index: 7;
`;

export const BurgerNavContainer = styled.span`
    display: none;

    @media (max-width: 992px) {
        display: inline;
    }
`;

export const Burger = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 25px;
    &:focus {
        outline: none;
    }

    div {
        position: absolute;
        z-index: 9;
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        background-color: ${props => props.color ? props.color : props.theme.colors.primary};

        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`;

export const BurgerNav = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.color ? props.color : props.theme.colors.background};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    height: 100vh;
    text-align: center;
    max-width: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const BurgerNavLink = styled(NavLink)`
    ${BodyFont}
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${props => props.color ? props.color : props.theme.fonts.body.color} !important;
    text-decoration: none;
    transition: all 0.3s linear;

    @media (max-width: 992px) {
        font-size: 1.5rem;
        text-align: center;
    }

    &:hover {
        background-color: ${props => props.theme.colors.primary};
    }

    &.${activeClassName} {
        background-color: ${props => props.theme.colors.primary};
    }

`;


