import React from 'react';
import { Helmet } from 'react-helmet-async';
import { CgPhone } from 'react-icons/cg';
import { useTheme } from 'styled-components';
import { Button } from '../../../utils/styles/forms';

import { Centered, Hr, Wrapper } from '../../../utils/styles/misc';
import { Body, H1, H3, LLink } from '../../../utils/styles/text';
import SimpleMap from '../../misc/SimpleMap';

function About(props){
    const theme = useTheme();
    return (
        <>
         <Wrapper>
            <Helmet>
                <title>About {props.site.name ? `| ${props.site.name}` : ""}</title>
            </Helmet>
            <H1>About</H1>
            <Body>
                Servicing Temecula Valley, California and surrounding areas.
            </Body>
            <Body>
                Active power solutions in a full service electrical company that provides great electrical services for residential and commercial. There is no job too small or large that our team can't do. 
                We make it a priority to stay up to date with the always changing electrical codes. We provide 24/7 emergency service. 
            </Body>
            <Centered>
                <H3>You can trust our technicians for quality work.<br/> Licensed and bonded.</H3>
                <LLink to={"/#contact"}>
                    <Button
                        type="button"
                        color={theme.colors.secondary}
                    >
                        <CgPhone size={20}/>&nbsp;Contact Us
                    </Button>
                </LLink>
            </Centered>
            
            <Hr/>
        </Wrapper>
                
        <SimpleMap />
        </>
       
    );
}

export default About;