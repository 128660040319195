import React from 'react';
import { Helmet } from 'react-helmet-async';
import { CgPhone } from 'react-icons/cg';
import { FaBolt, FaCar, FaLightbulb, FaPlug } from 'react-icons/fa';
import { useTheme } from 'styled-components';

import { SIZES } from '../../../utils/constants.js';
import { Button } from '../../../utils/styles/forms';
import { SlantBlock } from '../../../utils/styles/images.js';
import { BgMediaContainer, BgMediaModal, Wrapper, Centered, Column, Row, Grid } from '../../../utils/styles/misc';
import { Body, H1, H2, H3, H4, LLink } from '../../../utils/styles/text';
import ContactForm from '../../misc/ContactForm.js';

function Home(props){
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>Home {props.site.name ? `| ${props.site.name}` : ""}</title>
            </Helmet>
            <BgMediaContainer>
                <BgMediaModal noBg>
                    <H1>Keep your life powered</H1>
                    <Body size={SIZES.LG}>
                        Electrical Contractors Servicing Temecula Valley, California and surrounding areas.
                    </Body>
                    <LLink to={"/#contact"}>
                        <Button 
                            type="button"
                            size={SIZES.LG} 
                            color={theme.colors.primary}
                        >
                            Contact Us
                        </Button>
                    </LLink>
                </BgMediaModal>
            </BgMediaContainer>
            <SlantBlock angle={3}>
                <Wrapper>
                        <H2>What do we offer?</H2>
                        <Body>
                            Whether you need a whole house rewire or just a light switch replaced, you can trust Active Power Solutions for great services at honest prices.
                        </Body>
                        <Grid fluid>
                            <Row>
                                <Column sm={12} md={6} lg={3} textalign="center">
                                    <FaBolt color={theme.colors.yellow} size={80} />
                                    <H4>Electrical panel upgrades</H4>
                                    <Body size={SIZES.SM}>Whether you need a panel upgrade or replacing that old electrical panel we have you covered</Body>
                                </Column>
                                <Column sm={12} md={6} lg={3} textalign="center">
                                    <FaCar color={theme.colors.green} size={80} />
                                    <H4>Electric car chargers</H4>
                                    <Body size={SIZES.SM}>Need to add an electrical outlet for that new car? Our technicians are familiar with all makes and models of electric vehicles.</Body>
                                </Column>
                                <Column sm={12} md={6} lg={3} textalign="center">
                                    <FaLightbulb color={theme.colors.yellow} size={80} />
                                    <H4>LED lighting</H4>
                                    <Body size={SIZES.SM}>We specialize in both indoor and outdoor LED lighting.</Body>
                                </Column>
                                <Column sm={12} md={6} lg={3} textalign="center">
                                    <FaPlug color={theme.colors.blue} size={80} />
                                    <H4>Electrical Troubleshooting</H4>
                                    <Body size={SIZES.SM}>You can trust us to address and fix your electrical problems quickly and safely.</Body>
                                </Column>
                            </Row>
                        </Grid>
                        <Centered>
                            <H3>You can trust our technicians for quality work.<br/> Licensed and bonded.</H3>
                            <LLink to={"/#contact"}>
                                <Button 
                                    type="button"
                                    color={theme.colors.secondary}
                                >
                                    <CgPhone size={20}/>&nbsp;Contact Us
                                </Button>
                            </LLink>
                        </Centered>
                    </Wrapper>
            </SlantBlock>
            
            <Wrapper>
                <ContactForm />
            </Wrapper>
        </>
    );
}

export default Home;