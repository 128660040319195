import React from 'react';
import { NavLLink, NavALink, NavLogo, NavTitle, HeaderContainer, NavLinks, NavMenuContainer, BrandContainer, BrandLink } from '../../utils/styles/header';

function Header(props) {
    const navLinks = [
        {
            label: "home",
            path: "/",
            type: ""
        },
        {
            label: "about",
            path: "/about",
            type: ""
        },
        {
            label: "contact",
            path: "/#contact",
            type: "anchor"
        },
    ];
    
    return (
        <>
        <HeaderContainer>
            
            <BrandContainer>
                <BrandLink to="/" height={props.site.logo.height} end>
                    <NavLogo 
                        width={props.site.logo.width}
                        height={props.site.logo.height}
                        margin="0" 
                        // src={props.site.logo.url} 
                        src={require("../../assets/images/logos/icon512px.png")}
                    />
                    {props.site.logo.showTitle && (<NavTitle removeActiveStyle>{props?.site?.name ?? ""}</NavTitle>)}
                </BrandLink>
            </BrandContainer>
            {/* <Body display="inline" margin="0" size={SIZES.LG} color={theme.colors.primary} style={{}}><CgPhone size={20}/>&nbsp;<b>+1 (951) 526-8481</b></Body> */}
            {/* Desktop menu */}
            <NavMenuContainer>
                <NavLinks>
                    {navLinks.map((link, l) => {
                        if (link.type === "anchor") {
                            return (
                                <NavALink key={l} href={link.path}>{link.label}</NavALink>
                            )
                        } else {
                            return (
                                <NavLLink key={l} to={link.path}>{link.label}</NavLLink>
                            )
                        }
                    })}
                </NavLinks>
            </NavMenuContainer>
            {/* <BurgerNavContainer>
                <BgOverlay 
                    open={isBurgerMenuOpen} 
                    onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)} 
                />
                <Burger 
                    open={isBurgerMenuOpen} 
                    onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)}
                >
                    <div />
                    <div />
                    <div />
                </Burger>
                
                <BurgerNav open={isBurgerMenuOpen}>
                    {navLinks.map((link, l) => {
                        return (
                            <BurgerNavLink 
                                key={l} 
                                to={link.path}
                                onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)}
                            >
                                {link.label}
                            </BurgerNavLink>
                        )
                    })}
                </BurgerNav>
            </BurgerNavContainer> */}

        </HeaderContainer>
        </>
    )
}

export default Header;